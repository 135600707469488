import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VCalendar from 'v-calendar';
import 'v-calendar/dist/style.css';
import Notifications from '@kyvg/vue3-notification'
import VueFinalModal from 'vue-final-modal'
import axios from './plugins/axios';
import ECharts from 'vue-echarts'
import { use } from "echarts/core"
import { createI18n } from 'vue-i18n/index' 
import messages from './lang'

const i18n = createI18n({ 
  locale: "de",
  messages
});

import {
  CanvasRenderer
} from 'echarts/renderers'
import {
  BarChart
} from 'echarts/charts'
import {
  GridComponent,
  TooltipComponent
} from 'echarts/components'

use([
  CanvasRenderer,
  BarChart,
  GridComponent,
  TooltipComponent
])

const app = createApp(App);
app.use(store);
app.use(router);
app.use(VCalendar, {});
app.use(Notifications);
app.use(VueFinalModal());
app.use(i18n);
app.config.globalProperties.$http = axios;
app.component('v-chart', ECharts)
app.mount("#app");
