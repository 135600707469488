import store from '@/store'
import router from '@/router'
import axios from 'axios';

axios.interceptors.request.use(
  (request) => {
    /*if (store.getters.get_token) {
      request.headers.Authorization = store.getters.get_token;
    }*/
    return request;
  }
);

axios.interceptors.response.use(
  (response) => {
     // all 2xx/3xx responses will end here

     return response;
  },
  (error) => {
     // all 4xx/5xx responses will end here
     if (error.response.status === 401) {
       if (store.getters.is_logged_in == true) {
         store.commit('logout', '');
         router.push('/');
       }
     }
     return Promise.reject(error);
  }
);

export default axios;
