import { createStore } from "vuex";

export default createStore({
  state: {
    nsc_token: localStorage.getItem('nsc_token') || '',
    user : {},
    nsc_lang: localStorage.getItem('nsc_lang') || 'de',
  },
  mutations: {
    add_token (state, payload) {
      state.nsc_token = payload;
    },
    add_user (state, payload) {
      state.user = payload;
    },
    logout (state, payload) {
      console.log(payload);
      state.nsc_token = "";
      localStorage.setItem('nsc_token', "")
    },
    change_lang (state, payload) {
      state.nsc_lang = payload;
      localStorage.setItem('nsc_lang', payload)
    }
  },
  actions: {},
  modules: {},
  getters: {
    get_token: state => {
      return state.nsc_token
    },
    get_user: state => {
      return state.user
    },
    is_logged_in: state => !!state.nsc_token,
    get_nsc_lang: state => {
      return state.nsc_lang
    },
  }
});
